.icon {
	display: inline-flex;
	border-radius: 50%;
  background-color: get-color(primary, 1);
  width: 4rem;
  height: 4rem;
}

.react-router-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.react-router-link {
  box-shadow: 0 0.15rem get-color(primary, 1);
}